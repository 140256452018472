import {filter} from 'rxjs/operators';
import {AfterViewInit, Component, ElementRef, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {NavigationEnd, NavigationStart, Router, RouterOutlet} from '@angular/router';
import {environment} from '../environments/environment';
import {isPlatformBrowser} from '@angular/common';
import {UserInformationService} from './shared/user-information/user-info.service';
import {Subscription} from 'rxjs';
import {ExternalRefererService} from './shared/external-referer.service';
import {AppUpdateService} from "./shared/app-update.service";
import {LocalService} from "./shared/local.service";
import {FooterComponent} from './core/footer/footer.component';
import {HeaderComponent} from './core/header/header.component';

@Component({
  selector: 'app-vodeville',
  templateUrl: './app.component.html',
  imports: [HeaderComponent, RouterOutlet, FooterComponent]
})
export class AppComponent implements OnInit, AfterViewInit {

  private externalRefererCheckSubscription!: Subscription;

  constructor(private router: Router,
              private element: ElementRef,
              @Inject(PLATFORM_ID) private _platformId: Object,
              private externalRefererService: ExternalRefererService,
              private localStorageService: LocalService,
              private appUpdateService: AppUpdateService,
              private userService: UserInformationService) {
  }

  ngAfterViewInit() {
    if (isPlatformBrowser(this._platformId)) {
      this.router.events.pipe(
        filter(events => events instanceof NavigationStart || events instanceof NavigationEnd))
        .subscribe(events => {
          if (environment.production && events instanceof NavigationEnd) {
            // replace ; to GA and fake old school url since they cut the url otherwise
            let url = events.urlAfterRedirects
              .replace(';', '?')
              .replace(/;/g, '&');
            (<any>window).gtag('config', 'G-XFT7BZWLSG', {'page_location': url});
          }
        });

      // Adds external referer on films.
      this.externalRefererCheckSubscription = this.router.events.pipe(
        filter(events => events instanceof NavigationStart || events instanceof NavigationEnd))
        .subscribe(events => {
          if (events instanceof NavigationEnd) {
            if (events.urlAfterRedirects.startsWith('/film/')) {
              let idExpr = /(.*-)?(\d*)/;
              let idMatch = idExpr.exec(events.urlAfterRedirects);
              if (idMatch) {
                this.externalRefererService.addRefererForFilm(Number(idMatch[2]));
              }
            }
            this.externalRefererCheckSubscription.unsubscribe();
          }
        });

    }
  }

  ngOnInit(): void {
    if (!!this.localStorageService.getData('vodeville.authToken')) {
      this.userService.loadUserInformation();
    }
  }
}
