import {Component, OnInit, OnDestroy} from '@angular/core';
import {User} from '../../../model/user';
import {FilterUrlDto} from '../../../model/filterUrlDto';
import {Subscription} from 'rxjs';
import {UserInformationService} from '../../../shared/user-information/user-info.service';
import {Filter} from '../../../model/filter';

import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {RouterLink, RouterLinkActive} from '@angular/router';
import {faChartBar, faFilm, faLightbulb, faSearch, faSignIn, faUser} from "@fortawesome/pro-regular-svg-icons";

@Component({
  selector: 'app-footer-app-navigation',
  templateUrl: './footer-app-navigation.component.html',
  styleUrls: ['./footer-app-navigation.component.scss'],
  imports: [RouterLink, RouterLinkActive, FontAwesomeModule]
})
export class FooterAppNavigationComponent implements OnInit, OnDestroy {
  currentUser: User | undefined;
  defaultFilter: FilterUrlDto | undefined;
  protected readonly faFilm = faFilm;
  protected readonly faSearch = faSearch;
  protected readonly faLightbulb = faLightbulb;
  protected readonly faChartBar = faChartBar;
  protected readonly faSignIn = faSignIn;
  protected readonly faUser = faUser;
  private defaultFilterSubscription!: Subscription;
  private userSubscription!: Subscription;

  constructor(private userService: UserInformationService) {
  }

  ngOnDestroy(): void {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
    if (this.defaultFilterSubscription) {
      this.defaultFilterSubscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.userSubscription = this.userService.user$.subscribe(
      (currentUser: User | undefined) => {
        this.currentUser = currentUser;
      }
    );
    this.defaultFilterSubscription = this.userService.defaultFilter$.subscribe(
      (filter: Filter | undefined) => {
        if (filter) {
          this.defaultFilter = FilterUrlDto.fromFilter(filter);
          this.defaultFilter.default = true;
        } else {
          this.defaultFilter = undefined;
        }
      }
    );
  }
}
