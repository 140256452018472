import { Filter } from './filter';
import { ParamMap } from '@angular/router';
import { Genre } from './genre';
import { Provider } from './provider';
import { Region } from './region';
import { SortOrder } from './sortOrder';

export class FilterUrlDto {
  bechdel?: string;
  betyg?: number;
  bokmarkt?: string;
  default?: boolean;
  dolda?: string;
  fran?: number;
  genre?: string;
  hd?: string;
  kombinera?: string;
  land?: string;
  leverantor?: string;
  lista?: string;
  maxlangd?: number;
  maxpris?: number;
  med?: string;
  minlangd?: number;
  osedda?: string;
  region?: string;
  saknarImdb?: string;
  sedda?: string;
  sida?: number;
  sortering?: string;
  sprak?: string;
  till?: number;
  titel?: string;

//  irollistan: string;
//  regisseradav: string;
//  spokenLanguage = 'ALL';

  public static fromFilter(filter: Filter): FilterUrlDto {
    const f = new FilterUrlDto();
    if (filter.providers && filter.providers.length) {
      f.leverantor = filter.providers.map(p => p.name).join('-');
    }
    if (filter.genres && filter.genres.length) {
      f.genre = filter.genres.map(g => g.name.toLowerCase()).join('-');
    }
    if (filter.bechdelCertified) {
      f.bechdel = 'ja';
    }
    if (filter.combineGenres) {
      f.kombinera = 'ja';
    }
    if (filter.onlyHighDefinition) {
      f.hd = 'ja';
    }
    if (filter.rating) {
      f.betyg = filter.rating / 10;
    }
    if (filter.bookmarked) {
      f.bokmarkt = 'ja';
    }
    if (filter.price || filter.price === 0) {
      f.maxpris = filter.price;
    }
    if (filter.spokenLanguage) {
      switch (filter.spokenLanguage) {
        case 'SV':
          f.sprak = 'svenska';
          break;
        case 'EN':
          f.sprak = 'engelska';
          break;
      }
    }
    if (filter.minDuration) {
      f.minlangd = filter.minDuration;
    }
    if (filter.maxDuration) {
      f.maxlangd = filter.maxDuration;
    }
    if (filter.list) {
      f.lista = filter.list.name + '-' + filter.list.id;
    }
    if (filter.fromYear) {
      f.fran = filter.fromYear;
    }
    if (filter.toYear) {
      f.till = filter.toYear;
    }
    if (filter.region) {
      f.region = filter.region.code.toLowerCase();
    }
    if (filter.country) {
      f.land = filter.country.name + '-' + filter.country.code;
    }
    if (filter.including) {
      f.med = filter.including.name + '-' + filter.including.id;
    }
    switch (filter.paging.sortOrder) {
      case SortOrder.POPULARITY:
        f.sortering = 'popularitet';
        break;
      case SortOrder.YEAR:
        f.sortering = 'ar';
        break;
      case SortOrder.RATING:
        f.sortering = 'betyg';
        break;
      case SortOrder.LATEST:
        // default
        break;
      case SortOrder.TITLE:
        f.sortering = 'titel';
        break;
    }
    if (filter.onlyHidden) {
      f.dolda = 'ja';
    }
    if (filter.hideWatched) {
      f.osedda = 'ja';
    }
    if (filter.onlyWatched) {
      f.sedda = 'ja';
    }
    if (filter.paging.page > 1) {
      f.sida = filter.paging.page;
    }
    if (filter.title) {
      f.titel = filter.title;
    }
    if (filter.missingImdbId) {
      f.saknarImdb = 'ja';
    }
    return f;
  }

  public static toFilter(paramMap: ParamMap, providers: Provider[], genres: Genre[], regions: Region[]): Filter | undefined {
    if (!paramMap.keys.length) {
      return;
    }

    const f = new Filter();
    f.bechdelCertified = !!paramMap.get('bechdel');
    f.combineGenres = !!paramMap.get('kombinera');
    f.onlyHighDefinition = !!paramMap.get('hd');
    f.bookmarked = !!paramMap.get('bokmarkt');

    if (paramMap.get('betyg')) {
      f.rating = Number(paramMap.get('betyg')) * 10;
    }
    if (paramMap.get('maxpris')) {
      f.price = Number(paramMap.get('maxpris'));
    }
    if (paramMap.get('minlangd')) {
      f.minDuration = Number(paramMap.get('minlangd'));
    }
    if (paramMap.get('maxlangd')) {
      f.maxDuration = Number(paramMap.get('maxlangd'));
    }
    if (paramMap.get('fran')) {
      f.fromYear = Number(paramMap.get('fran'));
    }
    if (paramMap.get('till')) {
      f.toYear = Number(paramMap.get('till'));
    }
    if (paramMap.get('titel')) {
      f.title = paramMap.get('titel');
    }
    const listaParam = paramMap.get('lista');
    if (listaParam) {
      const listExpr = /(.*)-(\d*)/;
      const listMatch = listExpr.exec(listaParam);
      if (listMatch) {
        f.list = { name: listMatch[1], id: +listMatch[2] };
      }
    }
    const region = paramMap.get('region');
    if (region) {
      f.region = regions.find(r => r.code.toLowerCase() === region);
    }
    const landParam = paramMap.get('land');
    if (landParam) {
      const countryExpr = /(.*)-(.{2})/;
      const countryMatch = countryExpr.exec(landParam);
      if (countryMatch) {
        f.country = { name: countryMatch[1], code: countryMatch[2] };
      }
    }

    const medParam = paramMap.get('med');
    if (medParam) {
      const personExpr = /(.*)-(\d*)/;
      const personMatch = personExpr.exec(medParam);
      if (personMatch) {
        f.including = { name: personMatch[1], id: +personMatch[2], portraitUrl: '' };
      }
    }
    switch (paramMap.get('sortering')) {
      case 'popularitet':
        f.paging.sortOrder = SortOrder.POPULARITY;
        break;
      case 'ar':
        f.paging.sortOrder = SortOrder.YEAR;
        break;
      case 'betyg':
        f.paging.sortOrder = SortOrder.RATING;
        break;
      case 'senast-in':
        f.paging.sortOrder = SortOrder.LATEST;
        break;
      case 'titel':
        f.paging.sortOrder = SortOrder.TITLE;
        break;
    }

    if (paramMap.get('sprak')) {
      switch (paramMap.get('sprak')) {
        case 'svenska':
          f.spokenLanguage = 'SV';
          break;
        case 'engelska':
          f.spokenLanguage = 'EN';
          break;
      }
    }

    const genreParam = paramMap.get('genre');
    if (genreParam) {
      genreParam.split('-').forEach(genreName => {
        let genre = genres.find(g => g.name.toLowerCase() === genreName.toLowerCase());
        if (genre) {
          f.genres.push(genre);
        }
      });
    }
    const leverantorParam = paramMap.get('leverantor');
    if (leverantorParam) {
      leverantorParam.split('-').forEach(leverantor => {
        let provider = providers.find(p => p.name === leverantor);
        if (provider) {
          f.providers.push(provider);
        }
      });
    }
    if (paramMap.get('dolda') === 'ja') {
      f.onlyHidden = true;
    }
    if (paramMap.get('osedda') === 'ja') {
      f.hideWatched = true;
    }
    if (paramMap.get('sedda') === 'ja') {
      f.onlyWatched = true;
    }
    if (paramMap.get('saknarImdb') === 'ja') {
      f.missingImdbId = true;
    }
    if (paramMap.get('sida')) {
      f.paging.page = Number(paramMap.get('sida'));
    }

    return f;
  }
}
