import { Directive, HostListener, Inject, Input, PLATFORM_ID } from '@angular/core';
import { environment } from '../../../environments/environment';
import { isPlatformBrowser } from '@angular/common';

@Directive({
  selector: '[vvGa]',
  standalone: true
})
export class GoogleAnalyticsDirective {

  @Input() vvGa: any;

  constructor(@Inject(PLATFORM_ID) private _platformId: Object) {
  }

  @HostListener('click', ['$event']) onClick($event: any) {
    if (environment.production && isPlatformBrowser(this._platformId)) {
      (<any>window).gtag('event', this.vvGa.action, {
        'event_category': this.vvGa.category,
        'event_label': this.vvGa.label
      });
    }
  }

}
