import {ChangeDetectionStrategy, Component, OnDestroy, OnInit, signal, WritableSignal} from '@angular/core';
import {NavigationEnd, Router, RouterLink, RouterLinkActive} from '@angular/router';
import {Subscription} from 'rxjs';
import {User} from '../../model/user';
import {FilterUrlDto} from '../../model/filterUrlDto';
import {environment} from '../../../environments/environment';
import {UserInformationService} from '../../shared/user-information/user-info.service';
import {SearchBarComponent} from './searchbar/searchbar.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {GoogleAnalyticsDirective} from '../../shared/seo/google-analytics.directive';
import {NgClass} from '@angular/common';
import {StickyHeaderComponent} from './sticky-header.component';
import {faChartBar, faFilm, faLightbulb, faSearch, faSignIn, faUser} from '@fortawesome/pro-regular-svg-icons';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.scss'],
  imports: [StickyHeaderComponent, RouterLink, NgClass, GoogleAnalyticsDirective, FontAwesomeModule, RouterLinkActive, SearchBarComponent],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit, OnDestroy {
  currentUser: WritableSignal<User | undefined> = signal(undefined);
  defaultFilter: WritableSignal<FilterUrlDto | undefined> = signal(undefined);
  production: boolean;
  searchbarCollapsed = signal(true);
  searchbarDefault = signal(false);
  protected readonly faLightbulb = faLightbulb;
  protected readonly faFilm = faFilm;
  protected readonly faSearch = faSearch;
  protected readonly faChartBar = faChartBar;
  protected readonly faSignIn = faSignIn;
  protected readonly faUser = faUser;
  private defaultFilterSubscription!: Subscription;
  private userSubscription!: Subscription;

  constructor(private userService: UserInformationService, private router: Router) {
    this.production = environment.production;
  }

  ngOnDestroy(): void {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
    if (this.defaultFilterSubscription) {
      this.defaultFilterSubscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.userSubscription = this.userService.user$.subscribe(
      (currentUser) => {
        this.currentUser.set(currentUser);
      }
    );
    this.defaultFilterSubscription = this.userService.defaultFilter$.subscribe(
      (filter) => {
        if (filter) {
          let filterUrlDto = FilterUrlDto.fromFilter(filter);
          filterUrlDto.default = true;
          this.defaultFilter.set(filterUrlDto);
        } else {
          this.defaultFilter.set(undefined);
        }
      }
    );
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (event.url.includes('/hitta-film') || event.url === '/') {
          this.searchbarCollapsed.set(false);
          this.searchbarDefault.set(true);
        } else {
          this.searchbarCollapsed.set(true);
          this.searchbarDefault.set(false);
        }
      }
    });
  }

  searchbarFocus(): void {
    this.searchbarCollapsed.set(!this.searchbarCollapsed);
  }
}
