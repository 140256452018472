import { Provider } from './provider';
import { PagingDto } from './paging';
import { Genre } from './genre';
import { Country } from './country';
import { Region } from './region';
import { Person } from './person';
import { FilmList } from './filmList';

export class Filter {
  bechdelCertified?: boolean;
  bookmarked?: boolean;
  combineGenres?: boolean;
  country?: Country;
  directedBy?: Person;
  fromYear?: number;
  genres: Genre[] = [];
  hideWatched?: boolean;
  including?: Person;
  list?: FilmList;
  maxDuration?: number | undefined = undefined;
  minDuration?: number | undefined = undefined;
  missingImdbId?: boolean;
  onlyHidden?: boolean;
  onlyHighDefinition?: boolean;
  onlyWatched?: boolean;
  paging: PagingDto = new PagingDto();
  price?: number | undefined = undefined;
  providers: Provider[] = [];
  rating?: number;
  region?: Region;
  spokenLanguage? = 'ALL';
  starring?: Person;
  title?: string | null;
  toYear?: number;

  clear(): void {
    this.bechdelCertified = undefined;
    this.bookmarked = undefined;
    this.combineGenres = undefined;
    this.country = undefined;
    this.directedBy = undefined;
    this.fromYear = undefined;
    this.genres = [];
    this.hideWatched = undefined;
    this.onlyWatched = undefined;
    this.including = undefined;
    this.maxDuration = undefined;
    this.minDuration = undefined;
    this.onlyHighDefinition = undefined;
    this.paging = new PagingDto();
    this.price = undefined;
    this.providers = [];
    this.rating = undefined;
    this.region = undefined;
    this.spokenLanguage = 'ALL';
    this.starring = undefined;
    this.toYear = undefined;
    this.title = undefined;
  }

  count(): number {
    return this.countGenres()
      + this.countRatingCategory()
      + this.countCountryCategory()
      + this.countYearCategory()
      + this.countPriceCategory()
      + this.countDurationCategory();
  }

  countCountryCategory(): number {
    return (this.region ? 1 : 0)
      + (this.country ? 1 : 0)
      + (this.spokenLanguage && this.spokenLanguage !== 'ALL' ? 1 : 0);
  }

  countDurationCategory(): number {
    return (this.minDuration ? 1 : 0)
      + (this.maxDuration ? 1 : 0);
  }

  countGenres(): number {
    return this.genres.length;
  }

  countPriceCategory(): number {
    return (this.price ? 1 : 0);
  }

  countRatingCategory(): number {
    return (this.rating ? 1 : 0)
      + (this.bechdelCertified ? 1 : 0)
      + (this.onlyHighDefinition ? 1 : 0);
  }

  countYearCategory(): number {
    return (this.fromYear ? 1 : 0)
      + (this.toYear ? 1 : 0);
  }
}
