import { Injectable, Optional, SkipSelf } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { User } from '../../model/user';
import { Filter } from '../../model/filter';

@Injectable({
  providedIn: 'root',
})
export class UserInformationService {
  private defaultFilter = new BehaviorSubject<Filter | undefined>(undefined);
  defaultFilter$ = this.defaultFilter.asObservable();
  private user = new BehaviorSubject<User | undefined>(undefined);
  user$ = this.user.asObservable();

  constructor(private http: HttpClient, @Optional() @SkipSelf() parent?: UserInformationService) {
    // See https://indepth.dev/posts/1148/how-to-avoid-angular-injectable-instances-duplication
    if (parent) {
      throw Error(
        `[UserInformationService]: trying to create multiple instances,
        but this service should be a singleton.`
      );
    }
  }

  loadUserInformation() {
    this.http.get<User>('/api/profile/user').subscribe(user => this.setUser(user));
  }

  setUser(user: User) {
    this.user.next(user);
    this.defaultFilter.next(user.defaultFilter);
  }

  unset() {
    this.user.next(undefined);
    this.defaultFilter.next(undefined);
  }

  updateDefaultFilter(filter: Filter | undefined) {
    this.defaultFilter.next(filter);
  }

}
