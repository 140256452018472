<app-sticky-header>
  <header>
    <nav class="navbar navbar-expand-lg py-md-0 navbar-dark bg-dark">
      <div class="container-fluid">
        <a [routerLink]="['']" class="navbar-brand ps-sm-3 pt-0">
          <img alt="Vodeville" id="logotype" src="/assets/images/vodeville_1_yellow.svg"/>
        </a>
        <span class="d-md-none d-lg-block flex-grow-1 text-white">
        All film på ett ställe
      </span>
        <button (click)="searchbarFocus()"
                [ngClass]="searchbarCollapsed() ? 'btn-primary' : 'btn-link'"
                [vvGa]="{category:'search bar', action: searchbarCollapsed()? 'show search bar' : 'hide search bar'}"
                class="d-md-none btn app-search-btn-sm">
          <span class="sr-only sr-only-focusable">Sök film</span>
          @if (searchbarCollapsed()) {
            <fa-icon [icon]="faSearch" class="icon text-dark"></fa-icon>
          }
          @if (!searchbarCollapsed() && !searchbarDefault()) {
            <span class="text-white lead">&times;</span>
          }
        </button>
        <div class="navbar-inverse">
          <div
            class="navbar navbar-nav navbar-dark bg-dark navbar-expand-md justify-content-md-between justify-content-lg-end">
            <a [routerLinkActiveOptions]="{exact: true}" class="nav-item nav-link"
               routerLink="" routerLinkActive="active" tabindex="0">
              <fa-icon [icon]="faFilm" class="icon"></fa-icon>
              Hem
            </a>
            @if (!defaultFilter()) {
              <a class="nav-item nav-link" routerLink="hitta-film" routerLinkActive="active">
                <fa-icon [icon]="faSearch" class="icon"></fa-icon>
                Hitta film
              </a>
            }
            @if (defaultFilter()) {
              <a [routerLink]="['hitta-film', defaultFilter()]" class="nav-item nav-link"
                 routerLinkActive="active">
                <fa-icon [icon]="faSearch" class="icon"></fa-icon>
                Hitta film
              </a>
            }
            <a class="nav-item nav-link" routerLink="filmtips" routerLinkActive="active">
              <fa-icon [icon]="faLightbulb"></fa-icon>
              Filmtips
            </a>
            <a class="nav-item nav-link" routerLink="jamfor-tjanster" routerLinkActive="active">
              <fa-icon [icon]="faChartBar"></fa-icon>
              Filmtjänster
            </a>
            @if (!currentUser()) {
              <a class="nav-item nav-link" rel="nofollow" routerLink="login" routerLinkActive="active">
                <fa-icon [icon]="faSignIn"></fa-icon>
                Logga in
              </a>
            }
            @if (currentUser()) {
              <a class="nav-item nav-link" rel="nofollow" routerLink="/din-sida"
                 routerLinkActive="active">
                <fa-icon [icon]="faUser"></fa-icon>
                Din sida
              </a>
            }
          </div>
        </div>
      </div>
    </nav>
    @if (!production) {
      <div class="navbar-text app-viewport-text">
        <span class="d-block d-sm-none">XS</span>
        <span class="d-none d-sm-block d-md-none">SM</span>
        <span class="d-none d-md-block d-lg-none">MD</span>
        <span class="d-none d-lg-block d-xl-none">LG</span>
        <span class="d-none d-xl-block">XL</span>
      </div>
    }
    <div [ngClass]="{'d-none': searchbarCollapsed()}" class="d-md-block">
      <app-search-bar></app-search-bar>
    </div>
  </header>
</app-sticky-header>
