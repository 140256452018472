import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {TypeaheadHit} from '../../../shared/forms/film-typeahead/typeaheadHit';
import {FilmTypeAheadComponent} from '../../../shared/forms/film-typeahead/film-type-ahead/film-type-ahead.component';

@Component({
  selector: 'app-search-bar',
  templateUrl: './searchbar.component.html',
  imports: [FilmTypeAheadComponent]
})
export class SearchBarComponent {

  public constructor(private router: Router) {
  }

  public typeaheadOnSelect(item: TypeaheadHit): void {
    if (item.type === 'film') {
      this.router.navigate(['film', item.urlIdentifier]);
    } else if (item.type === 'person') {
      this.router.navigate(['/hitta-film', {med: item.name + '-' + item.id}]);
    }
  }
}
