<app-footer-app-navigation></app-footer-app-navigation>
<footer>
  <div class="site-footer">
    <div class="container-fluid">
      <div class="row mb-4 justify-content-center">
        <div class="col-12 col-sm-10 col-md-8 col-lg-6">
          <img alt="Vodeville" height="30" src="/assets/images/vodeville_1_yellow.svg" width="200"/>
        </div>
      </div>
      <div class="row mb-4 justify-content-center">
        <div class="col-12 col-sm-10 col-md-8 col-lg-6">
          <h1 class="h2 sr-only sr-only-focusable">Om Vodeville</h1>
          <ul class="fa-ul">
            <li>
              <fa-icon [icon]="faCheck" class="fa-li"></fa-icon>
              Sök hos alla filmtjänster samtidigt. Stora som små.
            </li>
            <li>
              <fa-icon [icon]="faCheck" class="fa-li"></fa-icon>
              Hitta bra hyrfilm, köpfilm och abonnemang.
            </li>
            <li>
              <fa-icon [icon]="faCheck" class="fa-li"></fa-icon>
              Filtrera på genre, betyg, tid, år, svenskt tal, Bechdel, land och mer.
            </li>
          </ul>
          <p class="d-grid"><a class="btn btn-primary btn-hollow" href="/om-vodeville">Om Vodeville</a></p>
        </div>
      </div>
      @if (!currentUser) {
        <div>
          <hr class="dashed-border border-white">
          <div class="row mb-4 justify-content-center" id="create-account-pros">
            <div class="col-12 col-sm-10 col-md-8 col-lg-6">
              <h2>Maxa ditt filmsök</h2>
              <ul class="fa-ul">
                <li>
                  <span class="lead">
                    <fa-icon [icon]="faBookmark" class="fa-li text-primary"></fa-icon>
                    Bokmärk filmer du vill se
                  </span><br>
                  <span>
                    Spara tid med alla önskelistor på ett ställe.
                  </span>
                </li>
                <li>
                  <span class="lead">
                    <fa-icon [icon]="faEnvelope" class="fa-li text-primary"></fa-icon>
                    Bevaka filmsläpp
                  </span><br>
                  <span>
                    Få mail när en film du vill se blir tillgänglig.
                  </span>
                </li>
                <li>
                  <span class="lead">
                    <fa-icon [icon]="faStar" class="fa-li text-primary"></fa-icon>
                    Trimma dina sökningar
                  </span><br>
                  <span>
                    Spara din favoritsökning och dölj filmer du sett eller aldrig vill se.
                  </span>
                </li>
                <li>
                  <span class="lead">
                    <fa-icon [icon]="faChartPie" class="fa-li text-primary"></fa-icon>
                    Få din filmsmak som personlig grafik
                  </span><br>
                  <span>
                    Betygsätt filmer och få fräsiga diagram över ditt filmtittande.
                  </span>
                </li>
              </ul>
              <p class="d-grid">
                <a class="btn btn-primary" href="/konto/skapa" rel="nofollow">
                  Gå med i Vodeville nu</a>
              </p>
            </div>
          </div>
        </div>
      }
      <hr class="dashed-border border-white">

      <div class="row justify-content-center mb-4">
        <div class="col-12 col-sm-10 col-md-8 col-lg-6 pb-4">
          <h2>Din feedback gör Vodeville bättre</h2>
          <p>Synpunkter? Berätta vad du saknar i ditt filmsökande och vad du tycker om Vodeville, så kan vi försöka fixa
            det.</p>
          <p class="d-grid">
            <a [routerLink]="['/feedback']" class="btn btn-primary">
              <fa-icon [icon]="faComment"></fa-icon>
              Ge oss din feedback
            </a>
          </p>
        </div>
      </div>
      <hr class="dashed-border border-white">
      <div class="row justify-content-center mb-4">
        <div class="col-12 col-sm-10 col-md-8 col-lg-6 pb-4">
          <h2>Få filmtips och senaste nytt</h2>
          <p>Följ oss gärna på Facebook. Då får du filmtips och blir först att få höra om nya funktioner som underlättar
            ditt filmtittande.</p>
          <p class="d-grid">
            <a [vvGa]="{category:'footer', action:'click like us on facebook'}"
               class="btn btn-primary btn-hollow"
               href="https://www.facebook.com/vodeville.se"
               rel="noopener"
               target="_blank">
              <fa-icon [icon]="faFacebookF"></fa-icon>&nbsp;
              Följ Vodeville på Facebook
            </a>
          </p>
        </div>
      </div>
      <hr class="dashed-border border-white">

      <div class="row justify-content-center mb-4">
        <div class="col-12 col-sm-10 col-md-8 col-lg-6">
          <h2 class="sr-only sr-only-focusable">Sitemap, integrititet och användarvillkor</h2>
          <div class="text-center">
            <ul class="list-inline">
              <li class="list-inline-item">
                <a [routerLink]="['/sitemap']" class="text-primary">Alla filmer</a>
              </li>
              <li class="list-inline-item">
                <a [routerLink]="['/om-vodeville/integritetspolicy']"
                   class="text-primary"
                   rel="nofollow">Integritetspolicy</a>
              </li>
              <li class="list-inline-item">
                <a [routerLink]="['/om-vodeville/anvandarvillkor']"
                   class="text-primary"
                   rel="nofollow">Användarvillkor</a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-12 col-sm-10 col-md-8 col-lg-6 text-center">
          <div class="d-inline-block">
            <img [ngSrc]="'/assets/images/logos/eu-flag.png'" alt="EU-flagga" class="me-2" height="27" width="40"/>
          </div>
          <div class="d-inline-block">
            <small>Medfinansierat av EU-programmet Kreativa Europa</small>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
